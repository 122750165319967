import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  UserInfoBalanceTable,
  UserInfoBalanceWrapp,
} from "../styledComponents/StyledHeader";
import { useTranslation } from "react-i18next";
import { StyledLoupeDiv } from "../styledComponents/StyledIndex";
import {
  MobileWalletDiv,
  MobileNavWrapper,
  NavIcons,
  StyledDepositIcon,
  WalletIcon,
  StyledMobileNavLink,
  StyledNewsIconGift,
  WalletModalWrapper,
  StyledFlexBetween,
  ProfileIcon,
  ProfileModalWrapper,
  MobileProfileDiv,
  MobileProfileHeader,
  StyledSwipeLine,
  StyledSlotMachine,
  HeaderAndThemeWrapper,
  StyledDice,
  StyledGamesWrapper,
  StyledSports,
  StyledlivecasinoNav,
  DiceWrapper,
  SlotWrapper,
} from "../styledComponents/StyltedMobileNavMenu";
import { printAmount } from "../helpers/functions";
import {
  StyledButton,
  StyledLink,
} from "../styledComponents/CommonStyledComponents";
import ProfileMenu from "./ProfileMenu";
import { ThemeSwitcher } from "./ThemeSwitcher";
import LanguageChange from "./LanguageChange";

const MobileNavMenu = ({
  pages,
  onToggleSearchShow,
  player,
  balance,
  serverSetup,
  hideSearch,
  showSearch,
  skin,
  currentTheme,
  setTheme,
  expandWallet,
  setExpandWallet,
  cancelWithdrawal,
}) => {
  const [expandProfile, setExpandProfile] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [showGamesMenu, setShowGamesMenu] = useState(false);
  const { t, i18n } = useTranslation("");
  const { currency, firstName, country } = player;
  const history = useHistory();
  const location = useLocation();
  let bodyTag = document.getElementsByTagName("BODY")[0];
  const isUK = country === "gb" ? true : false;
  const isSE = country === "se" ? true : false;
  const isSEgeoIp = serverSetup.geoIP.isoCode === "SE";

  useEffect(() => {
    return history.listen(() => {
      setExpandProfile(false);
      setExpandWallet(false);
      setShowGamesMenu(false);
      bodyTag.classList.remove("overflow-y-body");
    });
  }, [history]);

  useEffect(() => {
    if (expandWallet || expandProfile) {
      hideSearch();
    }
  }, [expandWallet, expandProfile]);

  useEffect(() => {
    if (skin !== "plc" && skin !== "pub" && skin !== "bnk") {
      sessionStorage.setItem("scrollToLobbyMobile", true);
    }
  }, []);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientY);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleTouchEnd = () => {
    if (touchEnd - touchStart > 50) {
      setTouchStart(0);
      setTouchEnd(0);
      setTimeout(() => {
        hideAll();
      }, 100);
    }
  };

  const hideAll = () => {
    setExpandWallet(false);
    setExpandProfile(false);
    setShowGamesMenu(false);
    bodyTag.classList.remove("overflow-y-body");
  };

  const scrollDeposit = () => {
    if (location.pathname === pages["deposit"]) {
      console.log("show deposit list");
      window.top.postMessage(
        JSON.stringify({
          error: false,
          list: true,
        }),
        window.location.origin
      );
    }
    scrollToTop();
    /* setExpandWallet(false);
    setExpandProfile(false);
    bodyTag.classList.remove("overflow-y-body"); */
  };

  const handleWithDepScroll = () => {
    if (location.pathname === pages["deposit"]) {
      console.log("show deposit list");
      window.top.postMessage(
        JSON.stringify({
          error: false,
          list: true,
        }),
        window.location.origin
      );
    }
    if (
      location.pathname === pages["deposit"] ||
      location.pathname === pages["withdrawal"]
    ) {
      scrollToTop();
    }
  };

  const handleWallet = () => {
    setExpandProfile(false);
    setExpandWallet(!expandWallet);
    setShowGamesMenu(false);
    if (!expandWallet) {
      bodyTag.className = "overflow-y-body";
    } else {
      bodyTag.classList.remove("overflow-y-body");
    }
  };

  const handleProfile = () => {
    setExpandWallet(false);
    setExpandProfile(!expandProfile);
    setShowGamesMenu(false);
    if (!expandProfile) {
      bodyTag.className = "overflow-y-body";
    } else {
      bodyTag.classList.remove("overflow-y-body");
    }
  };

  const handleDice = () => {
    setExpandWallet(false);
    setExpandProfile(false);
    setShowGamesMenu(!showGamesMenu);
  };

  const handleSlotMachine = () => {
    hideAll();
    history.push(
      skin === "abc" ||
        skin === "nbc" ||
        skin === "yet" ||
        skin === "fun" ||
        skin === "yak"
        ? pages["my-account"]
        : pages["games"]
    );
  };

  const handleSearch = (e) => {
    bodyTag.classList.remove("overflow-y-body");
    setExpandWallet(false);
    setExpandProfile(false);
    setShowGamesMenu(false);
    onToggleSearchShow(e);
  };

  return (
    <MobileNavWrapper>
      <NavIcons>
        {player && (
          <>
            <WalletIcon onClick={handleWallet} active={expandWallet ? 1 : 0} />
            <WalletModalWrapper toogle={expandWallet} onClick={hideAll} />
            <MobileWalletDiv
              expand={expandWallet}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <StyledSwipeLine />
              <UserInfoBalanceTable mobileNav>
                <UserInfoBalanceWrapp>
                  <div>
                    {t("label_balance", "Balance")}: <br></br>
                    {printAmount(
                      balance && balance.balance ? balance.balance : 0,
                      currency,
                      serverSetup.currencies,
                      false
                    )}
                  </div>
                  <div>
                    {t("label_cash", "Cash")}: <br></br>
                    {printAmount(
                      balance && balance.real ? balance.real : 0,
                      currency,
                      serverSetup.currencies,
                      false
                    )}
                  </div>
                  <div>
                    {t("label_bonus_header", "Bonus")}: <br></br>
                    {printAmount(
                      balance && balance.bonus ? balance.bonus : 0,
                      currency,
                      serverSetup.currencies,
                      false
                    )}
                  </div>
                  {skin !== "plc" &&
                    skin !== "pub" &&
                    balance &&
                    balance.pendingWithdrawal !== 0 && (
                      <div>
                        {t("label_pending_withdrawal")}: <br></br>
                        {printAmount(
                          balance && balance.bonus ? balance.bonus : 0,
                          currency,
                          serverSetup.currencies,
                          false
                        )}
                        {skin !== "plc" &&
                          skin !== "pub" &&
                          balance &&
                          balance.pendingWithdrawal !== 0 &&
                          !isSE &&
                          !isUK && (
                            <StyledButton
                              btn
                              cancelWithdrawal
                              type="button"
                              onClick={cancelWithdrawal}
                              dangerouslySetInnerHTML={{
                                __html: t("label_cancel", "label_cancel"),
                              }}
                            ></StyledButton>
                          )}
                      </div>
                    )}
                </UserInfoBalanceWrapp>
              </UserInfoBalanceTable>
              <StyledFlexBetween>
                <StyledLink
                  to={pages["withdrawal"]}
                  pub={skin === "pub" ? 1 : 0}
                  balancecolor={skin !== "pub" ? 1 : 0}
                  extrasize={
                    skin === "abc" ||
                    skin === "nbc" ||
                    skin === "yet" ||
                    skin === "fun" ||
                    skin === "cac" ||
                    skin === "hyc" ||
                    skin === "yak" ||
                    skin === "rac" ||
                    skin === "qbc"
                      ? 1
                      : 0
                  }
                  onClick={handleWithDepScroll}
                >
                  {t("label_withdrawal", "Withdrawal")}
                </StyledLink>
                <StyledLink
                  btn={skin !== "pub" ? 1 : 0}
                  to={pages["deposit"]}
                  pub={skin === "pub" ? 1 : 0}
                  goldenborder="true"
                  extrasize={
                    skin === "abc" ||
                    skin === "rac" ||
                    skin === "nbc" ||
                    skin === "yet" ||
                    skin === "fun" ||
                    skin === "cac" ||
                    skin === "hyc" ||
                    skin === "yak" ||
                    skin === "rac" ||
                    skin === "qbc"
                      ? 1
                      : 0
                  }
                  onClick={handleWithDepScroll}
                >
                  {t("label_deposit", "Deposit")}
                </StyledLink>
              </StyledFlexBetween>
            </MobileWalletDiv>
          </>
        )}
        {player && (
          <StyledLoupeDiv
            mobileNav
            active={showSearch ? 1 : 0}
            onClick={(e) => handleSearch(e)}
          />
        )}
        {!player && (
          <StyledNewsIconGift
            onClick={() => history.push(pages["news-and-promotions"])}
            active={location.pathname === pages["news-and-promotions"] ? 1 : 0}
          />
        )}
        {!player ? (
          <ProfileIcon
            notLogedIn
            onClick={() =>
              skin === "rac" &&
              isSEgeoIp &&
              location.pathname === pages["index"]
                ? null
                : window.location.replace(
                    pages[skin === "rac" && isSEgeoIp ? "index" : "login"]
                  )
            }
            active={location.pathname === pages["login"] ? 1 : 0}
          />
        ) : (
          <StyledMobileNavLink
            deposit="true"
            link="true"
            onClick={scrollDeposit}
            to={pages["deposit"]}
          >
            <StyledDepositIcon
              active={location.pathname === pages["deposit"] ? 1 : 0}
            />
          </StyledMobileNavLink>
        )}
        {/* {player && (
          <StyledMobileNavLink
            onClick={hideAll}
            to={
              skin === "abc" ||
              skin === "nbc" ||
              skin === "yet" ||
              skin === "fun" ||
              skin === "yak"
                ? pages["my-account"]
                : pages["games"]
            }
          >
            <StyledSlotMachine
              active={
                skin === "abc" ||
                skin === "nbc" ||
                skin === "yet" ||
                skin === "fun" ||
                skin === "yak"
                  ? location.pathname === pages["my-account"]
                  : location.pathname === pages["games"]
              }
            />
          </StyledMobileNavLink>
        )} */}
        {skin === "abc" || skin === "pub" || skin === "qbc" ? (
          <DiceWrapper>
            <StyledGamesWrapper showGamesMenu={showGamesMenu}>
              {showGamesMenu && (
                <StyledSports
                  showGamesMenu={showGamesMenu}
                  onClick={() =>
                    history.push(`/${i18n.language}/sports-book/sports`)
                  }
                  active={
                    location.pathname === `/${i18n.language}/sports-book/sports`
                  }
                />
              )}
              {showGamesMenu && (
                <StyledlivecasinoNav
                  onClick={() => history.push(pages["livecasino"])}
                  active={location.pathname === pages["livecasino"]}
                />
              )}
              {showGamesMenu && (
                <StyledSlotMachine
                  onClick={() => handleSlotMachine()}
                  active={
                    skin === "abc" ||
                    skin === "nbc" ||
                    skin === "yet" ||
                    skin === "fun" ||
                    skin === "yak"
                      ? location.pathname === pages["my-account"]
                      : location.pathname === pages["games"]
                  }
                />
              )}
              <StyledDice
                logedIn={player}
                showGamesMenu={showGamesMenu}
                onClick={() => handleDice()}
                /* active={showGamesMenu} */
              />
            </StyledGamesWrapper>
          </DiceWrapper>
        ) : player ? (
          <SlotWrapper>
            <StyledSlotMachine
              onClick={() => handleSlotMachine()}
              active={
                skin === "abc" ||
                skin === "nbc" ||
                skin === "yet" ||
                skin === "fun" ||
                skin === "yak"
                  ? location.pathname === pages["my-account"]
                  : location.pathname === pages["games"]
              }
            />
          </SlotWrapper>
        ) : (
          <StyledLoupeDiv
            mobileNav
            active={showSearch ? 1 : 0}
            onClick={(e) => handleSearch(e)}
          />
        )}
        {player && (
          <>
            <ProfileIcon
              onClick={handleProfile}
              active={expandProfile ? 1 : 0}
            />
            <ProfileModalWrapper toogle={expandProfile} onClick={hideAll} />
            <MobileProfileDiv
              expand={expandProfile}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <StyledSwipeLine />
              <HeaderAndThemeWrapper>
                <MobileProfileHeader>
                  {t("label_hello", "hello")} {firstName + "!"}
                </MobileProfileHeader>
                {(skin === "abc" || skin === "rac") && (
                  <ThemeSwitcher
                    currentTheme={currentTheme}
                    setTheme={setTheme}
                    player={player}
                    skin={skin}
                    server={serverSetup}
                  />
                )}
                {(skin === "nbc" ||
                  skin === "yet" ||
                  skin === "fun" ||
                  skin === "cac" ||
                  skin === "hyc" ||
                  skin === "yak") && (
                  <LanguageChange serverSetup={serverSetup} />
                )}
              </HeaderAndThemeWrapper>
              <ProfileMenu
                serverSetup={serverSetup}
                profileMenu={true}
                isMobile={true}
                pages={pages}
                showProfileMenu={true}
              />
              <StyledFlexBetween profile="true">
                <StyledLink
                  third={
                    skin === "abc" ||
                    skin === "nbc" ||
                    skin === "yet" ||
                    skin === "fun" ||
                    skin === "cac" ||
                    skin === "hyc" ||
                    skin === "yak" ||
                    skin === "rac" ||
                    skin === "qbc"
                      ? 1
                      : 0
                  }
                  pub={skin === "pub" ? 1 : 0}
                  goldenborder={skin === "pub" ? 1 : 0}
                  extrasize={
                    skin === "abc" ||
                    skin === "nbc" ||
                    skin === "yet" ||
                    skin === "fun" ||
                    skin === "cac" ||
                    skin === "hyc" ||
                    skin === "yak" ||
                    skin === "rac" ||
                    skin === "qbc"
                      ? 1
                      : 0
                  }
                  to={pages["support"]}
                  dangerouslySetInnerHTML={{
                    __html: t("label_support", "support"),
                  }}
                ></StyledLink>
                <StyledLink
                  pub={skin === "pub" ? 1 : 0}
                  goldenborder={skin === "pub" ? 1 : 0}
                  logout="true"
                  balancecolor={
                    skin === "abc" ||
                    skin === "nbc" ||
                    skin === "yet" ||
                    skin === "fun" ||
                    skin === "cac" ||
                    skin === "hyc" ||
                    skin === "yak" ||
                    skin === "rac" ||
                    skin === "qbc"
                      ? 1
                      : 0
                  }
                  extrasize={
                    skin === "abc" ||
                    skin === "nbc" ||
                    skin === "yet" ||
                    skin === "fun" ||
                    skin === "cac" ||
                    skin === "hyc" ||
                    skin === "yak" ||
                    skin === "rac" ||
                    skin === "qbc"
                      ? 1
                      : 0
                  }
                  to={pages["logout"]}
                  dangerouslySetInnerHTML={{
                    __html: t("label_logout", "Logout"),
                  }}
                ></StyledLink>
              </StyledFlexBetween>
            </MobileProfileDiv>
          </>
        )}
      </NavIcons>
    </MobileNavWrapper>
  );
};

export default MobileNavMenu;
