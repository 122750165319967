import React from "react";
import {
  StyledLink,
  StyledNavLink,
} from "../styledComponents/CommonStyledComponents";
import { useTranslation } from "react-i18next";
import { StyledQuickGameCategories } from "../styledComponents/StyledGamesCategories";
import { useLocation } from "react-router-dom";

const QuickGameCategories = ({
  currentLanguage,
  games,
  allLinks,
  player,
  hideOnScroll,
}) => {
  const { t } = useTranslation();

  let location = useLocation();

  return allLinks ? (
    <StyledQuickGameCategories player={player} hideOnScroll={!hideOnScroll}>
      <StyledNavLink
        activeClassName="active"
        to={"/" + currentLanguage + "/sports-book/sports#/overview"}
      >
        {t("label_sportsbook")}
      </StyledNavLink>
      <StyledNavLink
        activeClassName="active"
        to={"/" + currentLanguage + (games ? "/games" : "/index")}
      >
        {t("label_casino")}
      </StyledNavLink>
      <StyledNavLink
        activeClassName="active"
        to={"/" + currentLanguage + "/livecasino"}
      >
        {t("label_live_casino")}
      </StyledNavLink>
    </StyledQuickGameCategories>
  ) : (
    <StyledQuickGameCategories player={player} hideOnScroll={!hideOnScroll}>
      {location.pathname !== `/${currentLanguage}/livecasino` && (
        <StyledLink to={"/" + currentLanguage + "/livecasino"}>
          {t("label_live_casino")}
        </StyledLink>
      )}
      {(location.pathname === `/${currentLanguage}/livecasino` ||
        location.pathname === `/${currentLanguage}/sports-book/sports`) && (
        <StyledLink to={"/" + currentLanguage + (games ? "/games" : "/index")}>
          {t("label_casino")}
        </StyledLink>
      )}
      {location.pathname !== `/${currentLanguage}/sports-book/sports` && (
        <StyledLink
          to={"/" + currentLanguage + "/sports-book/sports#/overview"}
        >
          {t("label_sportsbook")}
        </StyledLink>
      )}
    </StyledQuickGameCategories>
  );
};

export default QuickGameCategories;
