import React from "react";
import { printAmount } from "common/src/helpers/functions";
import { isMobile, isMobileOnly } from "react-device-detect";
import {
  StyledBalanceWrapper,
  StyledMaterialIcon,
} from "../styledComponents/StyledHeader";

const PlayerBalance = ({
  serverSetup,
  balance,
  player,
  headerUserExpand,
  setHeaderUserExpand,
  skin,
  hideOnScroll,
  expandWallet,
  setExpandWallet,
  racInGame,
}) => {
  const { currency, firstName } = player;

  const handleExpendMenu = () => {
    if (window.scrollY < 100) {
      window.scrollTo(0, 0);
      setHeaderUserExpand(!headerUserExpand);
    } else {
      setHeaderUserExpand(!headerUserExpand);
      window.scrollTo(0, 0);
    }
  };

  const openWallet = () => {
    setExpandWallet(!expandWallet);
  };

  let mobileTypeA = skin === "pub" && isMobileOnly;
  let mobileTypeB =
    (skin === "abc" ||
      skin === "nbc" ||
      skin === "yet" ||
      skin === "fun" ||
      skin === "cac" ||
      skin === "hyc" ||
      skin === "yak" ||
      skin === "qbc") &&
    isMobileOnly;
  let mobileTypeC = skin === "rac" && isMobileOnly;

  return (
    <StyledBalanceWrapper
      expand={!headerUserExpand}
      hideOnScroll={hideOnScroll}
      racInGame={racInGame}
      onClick={
        mobileTypeA
          ? null
          : mobileTypeB || mobileTypeC
          ? openWallet
          : handleExpendMenu
      }
    >
      <StyledMaterialIcon
        icon={headerUserExpand}
        mobileType={mobileTypeA || mobileTypeB || mobileTypeC}
      >
        {skin === "plc" ? firstName : !isMobile ? firstName : null}{" "}
        {printAmount(balance.balance, currency, serverSetup.currencies, false)}
      </StyledMaterialIcon>
    </StyledBalanceWrapper>
  );
};

export default PlayerBalance;
