import {
  DescriptionWrapper,
  InnderDescriptionWrapper,
} from "common/src/styledComponents/StyledSlider";
import { Logo18 } from "common/src/styledComponents/StyledFooter";
import { ReactComponent as Logo18Svg } from "common/src/assets/img/logo_18.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";

const MainDescription = ({ pages, skin, qbcIndex }) => {
  const { t } = useTranslation("");
  let indexLocation = pages && location.pathname === pages["index"];
  return (
    <DescriptionWrapper
      qbcIndex={qbcIndex}
      maindescription={skin ? false : indexLocation}
    >
      <InnderDescriptionWrapper qbcIndex={qbcIndex}>
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              "label_welcome_bonus" /* ,
              "Welcome bonus for new players only | Maximum bonus is 100% up to £100 | Min. deposit is £20 | No max cash out | Wagering is 35x bonus | Maximum bet with an active bonus is £5 | Eligibility is restricted for suspected abuse | Cashback is cash with no restrictions | Skrill & Neteller deposits excluded | Cashback applies to deposits where no bonus is included | T&C's apply" */
            ),
          }}
        ></div>
        {skin !== "cac" && skin !== "hyc" && skin !== "abc" && (
          <Logo18
            maindescription={skin ? false : indexLocation}
            qbcIndex={qbcIndex}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  "label_footer-text-top" /* ,
                  `Gambling can be addictive, <br>play responsibily.</br>` */
                ),
              }}
            ></div>
            <Logo18Svg
              height={isMobileOnly ? "30px" : "22px"}
              id="scroll-to-category"
            />
          </Logo18>
        )}
        {skin === "abc" && (
          <div>
            <Logo18Svg height="30px" id="scroll-to-category" />
          </div>
        )}
      </InnderDescriptionWrapper>
    </DescriptionWrapper>
  );
};

export default MainDescription;
