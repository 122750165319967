import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { gameCategoryToRoute } from "common/src/helpers/functions";
import { Game } from "./Game";
import { useTranslation } from "react-i18next";
import { ReactComponent as Telescope } from "common/src/assets/icon-component/telescope.svg";
import { isTablet } from "react-device-detect";
import {
  AlignCategoryTitle,
  CategoryTitle,
  EmptyCategoriy,
  GameCategoryContent,
  GameCategoryHeader,
  GameCategoryTitle,
  GamesGriddy,
  ShowAllArrow,
  ShowAllGames,
} from "../styledComponents/StyledGame";
import { GameThemeProviders } from "./GameThemeProviders";
/* import DGA from "../helpers/dgaAPI"; */

const Lobby = ({
  gameLobby,
  gamesForCategory,
  selectedCategory,
  width,
  skin,
  gameImagePath,
  config,
  country,
  mobGamesInLobby,
  providerIsChosen,
  setSelectedGameType,
  setThemeSearchTerm,
  themeSerachTerm,
  setSearchResults,
  accountRoute,
  setSpinner,
  setThemeExists,
  setSelectedCategory,
  themes,
}) => {
  const { t, i18n } = useTranslation("");
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const baseUrl = `/${i18n.language || window.localStorage.i18nextLng}`;
  //for plc show games by category
  gamesForCategory =
    (skin === "plc" ||
      skin === "pub" ||
      skin === "rac" ||
      skin === "cac" ||
      skin === "hyc") &&
    gamesForCategory;

  /*  if (skin !== "plc") {
    let DGAUri = config.wsPragmaticPath;
    let DGACasinoId = config.pragmaticCID_MGA;
    if (country === "se") {
      DGACasinoId = config.pragmaticCID_SE;
    } else if (country === "uk") {
      DGACasinoId = config.pragmaticCID_UK;
    }

    const { connect, disconnect, tables } = DGA(DGAUri, DGACasinoId);

    useEffect(() => {
      connect(config.wsPragmaticPath);
      return () => disconnect();
    }, []);
  } */

  //let display = (skin === "abc" || skin === "nbc") && isMobile ? 4 : 2000;
  let hideNameForPub =
    (skin === "pub" || skin === "cac" || skin === "hyc") &&
    location.pathname === baseUrl + "/index"
      ? false
      : true;

  return (
    <>
      {gameLobby !== null &&
        gameLobby.map((category, index) => {
          let selected =
            selectedCategory === undefined ||
            selectedCategory === category.categoryName ||
            selectedCategory === null ||
            (category.categoryName === "rapid-fire-jackpots" &&
              selectedCategory === "popular-games" &&
              (skin === "pub" || skin === "hyc" || skin === "cac")) ||
            ((category.categoryName === "game-of-week" ||
              category.categoryName === "game-of-month" ||
              category.categoryName === "game-of-year") &&
              selectedCategory === "popular-games")
              ? false
              : true;
          return category.categoryName === "themes" && themes ? (
            <>
              <GameCategoryHeader provider gameTheme>
                <GameCategoryTitle category>
                  {t(category.title, "Chose Theme:")}
                </GameCategoryTitle>
              </GameCategoryHeader>
              <GameThemeProviders
                setThemeSearchTerm={setThemeSearchTerm}
                themeSerachTerm={themeSerachTerm}
                setSearchResults={setSearchResults}
                skin={config}
                accountRoute={accountRoute}
                serverSetup={serverSetup}
                setSpinner={setSpinner}
                setSelectedCategory={setSelectedCategory}
                setThemeExists={setThemeExists}
              />
            </>
          ) : skin === "abc" &&
            (category.categoryName === "sports-book" ||
              category.categoryName === "live-casino") ? null : (
            <GameCategoryContent
              hide={selected}
              removeMargin={category.games.length}
              key={category.categoryName}
            >
              {category.title && hideNameForPub && (
                <GameCategoryHeader>
                  <CategoryTitle>
                    {t(
                      category.categoryName === "popular-games"
                        ? "label_popular"
                        : category.title
                    )}
                  </CategoryTitle>
                  {skin !== "pub" &&
                    !gamesForCategory &&
                    category.categoryName !== "game-of-week" &&
                    category.categoryName !== "game-of-month" &&
                    category.categoryName !== "game-of-year" &&
                    category.categoryName !== "sports-book" && (
                      <Link
                        to={`/${
                          serverSetup.currentLanguage
                        }/${gameCategoryToRoute(category.categoryName)}`}
                      >
                        <ShowAllGames>
                          {t("label_show_all")}
                          <ShowAllArrow>&rsaquo;</ShowAllArrow>
                        </ShowAllGames>
                      </Link>
                    )}
                </GameCategoryHeader>
              )}
              {category.title &&
                (skin === "cac" || skin === "hyc" || skin === "pub") &&
                window.location.pathname !==
                  `/${serverSetup.currentLanguage}/games` && (
                  <GameCategoryHeader>
                    {category.categoryName !== "game-of-week" &&
                      category.categoryName !== "game-of-month" &&
                      category.categoryName !== "game-of-year" && (
                        <Link
                          to={`/${
                            serverSetup.currentLanguage
                          }/${gameCategoryToRoute(category.categoryName)}`}
                        >
                          <ShowAllGames>
                            {t("label_show_all")}
                            <ShowAllArrow>&rsaquo;</ShowAllArrow>
                          </ShowAllGames>
                        </Link>
                      )}
                  </GameCategoryHeader>
                )}
              {!gamesForCategory ? (
                <>
                  <GamesGriddy
                    remainder={index % 2}
                    specialGame={
                      category.categoryName === "game-of-week" ||
                      category.categoryName === "game-of-month" ||
                      category.categoryName === "game-of-year" ||
                      (category.categoryName === "sports-book" &&
                        skin === "rac")
                    }
                  >
                    <Game
                      width={width}
                      categoryName={category.categoryName}
                      games={
                        mobGamesInLobby
                          ? isTablet
                            ? category.games.slice(0, 9)
                            : category.games.slice(0, 4)
                          : /* : skin === "rac"
                          ? category.games.slice(0, 7) */
                          skin === "abc" &&
                            category.categoryName === "popular-games"
                          ? category.games.filter(
                              (el) => el.gameType !== "Live Casino"
                            )
                          : category.games
                      }
                      skin={skin}
                      gameImagePath={
                        category.categoryName === "game-of-week" ||
                        category.categoryName === "game-of-month" ||
                        category.categoryName === "game-of-year"
                          ? gameImagePath + "gameof/"
                          : gameImagePath
                      }
                    />
                  </GamesGriddy>
                  {skin === "pub" &&
                    (providerIsChosen ? (
                      <AlignCategoryTitle>
                        {!gamesForCategory && (
                          <Link
                            to={`/${serverSetup.currentLanguage}/games`}
                            onClick={() => setSelectedGameType(null)}
                          >
                            <ShowAllGames>
                              {t("label_back_to_games")}
                              <ShowAllArrow>&lsaquo;</ShowAllArrow>
                            </ShowAllGames>
                          </Link>
                        )}
                      </AlignCategoryTitle>
                    ) : (
                      <AlignCategoryTitle>
                        {!gamesForCategory &&
                          category.categoryName !== "game-of-week" &&
                          category.categoryName !== "game-of-month" &&
                          category.categoryName !== "game-of-year" && (
                            <Link
                              to={`/${
                                serverSetup.currentLanguage
                              }/${gameCategoryToRoute(category.categoryName)}`}
                            >
                              <ShowAllGames>
                                {t("label_show_all")}
                                <ShowAllArrow>&rsaquo;</ShowAllArrow>
                              </ShowAllGames>
                            </Link>
                          )}
                      </AlignCategoryTitle>
                    ))}
                  {(skin === "plc" || skin === "cac" || skin === "hyc") &&
                    providerIsChosen && (
                      <AlignCategoryTitle>
                        {!gamesForCategory && (
                          <Link
                            to={`/${serverSetup.currentLanguage}/games`}
                            onClick={() => setSelectedGameType(null)}
                          >
                            <ShowAllGames>
                              {t("label_back_to_games")}
                              <ShowAllArrow>&lsaquo;</ShowAllArrow>
                            </ShowAllGames>
                          </Link>
                        )}
                      </AlignCategoryTitle>
                    )}
                </>
              ) : (
                <GamesGriddy
                  opened={skin !== "rac"}
                  racGameCategorie={skin === "rac"}
                  specialGame={
                    category.categoryName === "game-of-week" ||
                    category.categoryName === "game-of-month" ||
                    category.categoryName === "game-of-year" ||
                    (category.categoryName === "sports-book" && skin === "rac")
                  }
                >
                  <Game
                    width={width}
                    categoryName={category.categoryName}
                    gamesForCategory={gamesForCategory}
                    games={
                      skin === "abc" &&
                      category.categoryName === "popular-games"
                        ? category.games.filter(
                            (el) => el.gameType !== "Live Casino"
                          )
                        : category.games
                    }
                    skin={skin}
                    gameImagePath={
                      category.categoryName === "game-of-week" ||
                      category.categoryName === "game-of-month" ||
                      category.categoryName === "game-of-year"
                        ? gameImagePath + "gameof/"
                        : gameImagePath
                    }
                  />
                </GamesGriddy>
              )}
            </GameCategoryContent>
          );
        })}
      {location.pathname === `${baseUrl}/index` && (
        <EmptyCategoriy
          hide={gameLobby.filter((el) => el.games.length > 0).length}
        >
          <Telescope />
          <h2> {t("label_unable_to_find", "No results found!")}</h2>
          <div>
            {t(
              "label_unable_to_find_discription",
              "We are unable to find any games by selected provider"
            )}
          </div>
        </EmptyCategoriy>
      )}
    </>
  );
};

export default withRouter(Lobby);
