import styled from "styled-components";
import {
  isTablet,
  isMobile,
  isMobileOnly,
  isDesktop,
} from "react-device-detect";
import { handleOrientationChange } from "common/src/helpers/isPortrait";
import profileBubble from "../../../plc/src/data/icons/profile-buble.svg";
import profileBubbleDark from "../../../plc/src/data/icons/profile-buble-fill.svg";
import profileBubleGray from "../../../plc/src/data/icons/profile-buble-gray.svg";
import bonusBubble from "../../../plc/src/data/icons/bonus-buble.svg";
import bonusBubbleDark from "../../../plc/src/data/icons/bonus-buble-fill.svg";
import bonusBubbleWhite from "../../../plc/src/data/icons/bonus-buble-white.svg";
import CloudTop from "./../../../plc/src/data/img/bg_clouds_light.svg";
import CloudBottom from "./../../../plc/src/data/img/bg_bottom_light.svg";
import CloudTopDark from "./../../../plc/src/data/img/bg_clouds_dark.svg";
import CloudBottomDark from "./../../../plc/src/data/img/bg_bottom_dark.svg";
import BanerLightABC from "./../../../abc/src/data/img/header_bg_light.jpg";
import BanerDarkABC from "./../../../abc/src/data/img/header_bg_dark.jpg";
import BanerNBC from "./../../../nbc/src/data/img/header_bg_nbc.jpg";
import BanerYET from "./../../../yet/src/data/img/header_bg.jpg";
import BanerFUN from "./../../../fun/src/data/img/header_bg.jpg";
import BanerCAC from "./../../../cac/src/data/img/header_bg.jpg";
import BanerHYC from "./../../../hyc/src/data/img/bg_desktop.jpg";
import BanerYAK from "./../../../yak/src/data/img/crown_and_coins.svg";
import BanerQBC from "./../../../qbc/src/data/img/sportsbook-banner.jpg";

// PLC ICONS
import { ReactComponent as Profile } from "./../../../plc/src/data/icon-component/myprofile.svg";
import { ReactComponent as Bonus } from "./../../../plc/src/data/icon-component/bonus.svg";
import { ReactComponent as Cashback } from "./../../../plc/src/data/icon-component/cachback.svg";
import { ReactComponent as NewPassword } from "./../../../plc/src/data/icon-component/newpasword.svg";
import { ReactComponent as Transactions } from "./../../../plc/src/data/icon-component/transactions.svg";
import { ReactComponent as Hisory } from "./../../../plc/src/data/icon-component/history.svg";
import { ReactComponent as Promotion } from "./../../../plc/src/data/icon-component/promotion.svg";
import { ReactComponent as Limits } from "./../../../plc/src/data/icon-component/limits.svg";
import { ReactComponent as Verification } from "./../../../plc/src/data/icon-component/verification.svg";
// ABC ICONS
import { ReactComponent as ProfileABC } from "./../../../abc/src/data/icon-component/profile_icons_profile.svg";
import { ReactComponent as BonusABC } from "./../../../abc/src/data/icon-component/profile_icons_bonuses.svg";
import { ReactComponent as CashbackABC } from "./../../../abc/src/data/icon-component/profile_icons_cashback.svg";
import { ReactComponent as NewPasswordABC } from "./../../../abc/src/data/icon-component/profile_icons_password.svg";
import { ReactComponent as TransactionsABC } from "./../../../abc/src/data/icon-component/profile_icons_transactions.svg";
import { ReactComponent as HisoryABC } from "./../../../abc/src/data/icon-component/history.svg";
import { ReactComponent as PromotionABC } from "./../../../abc/src/data/icon-component/profile_icons_newspromos.svg";
import { ReactComponent as LimitsABC } from "./../../../abc/src/data/icon-component/limits.svg";
import { ReactComponent as VerificationABC } from "./../../../abc/src/data/icon-component/profile_icons_verify.svg";
import { ReactComponent as TournamentsABC } from "./../../../abc/src/data/icon-component/icon-trophy_trophy.svg";

// PUB ICONS
import BanerPub from "./../../../pub/src/data/img/login-banner-pub.jpg";
import { ReactComponent as ProfilePUB } from "./../../../pub/src/data/icon-component/myprofile.svg";
import { ReactComponent as CashbackPUB } from "./../../../pub/src/data/icon-component/profile_icons_cashback.svg";
import { ReactComponent as BonusPUB } from "./../../../pub/src/data/icon-component/profile_icons_bonuses.svg";
import { ReactComponent as NewPasswordPUB } from "./../../../pub/src/data/icon-component/profile_icons_password.svg";
import { ReactComponent as TransactionsPUB } from "./../../../pub/src/data/icon-component/profile_icons_transactions.svg";
import { ReactComponent as HisoryPUB } from "./../../../pub/src/data/icon-component/history.svg";
import { ReactComponent as PromotionsPUB } from "./../../../pub/src/data/icon-component/promotion-pub.svg";
import { ReactComponent as LimitsPUB } from "./../../../pub/src/data/icon-component/limits.svg";
import { ReactComponent as VerificationPUB } from "./../../../pub/src/data/icon-component/profile_icons_verify.svg";
import { ReactComponent as TournamentsPUB } from "./../../../pub/src/data/icon-component/icon-trophy_trophy.svg";

const orientation = () => {
  if (isTablet) {
    window.addEventListener("orientationchange", handleOrientationChange);
    return () =>
      window.removeEventListener("orientationchange", handleOrientationChange);
  }
};

const bubleBackground = (props) => {
  if (props.profile) {
    return `${
      props.theme.current === "light"
        ? `url(${bonusBubble})`
        : `url(${bonusBubbleDark})`
    };`;
  } else if (props.cashback) {
    return `${
      props.theme.current === "light"
        ? `url(${profileBubble})`
        : `url(${profileBubbleDark})`
    };`;
  }
};

const bubleBackgroundSecond = (props) => {
  if (props.profile) {
    return `${
      props.theme.current === "light"
        ? `url(${bonusBubbleWhite})`
        : `url(${bonusBubbleDark})`
    };`;
  } else if (props.cashback) {
    return `${
      props.theme.current === "light"
        ? `url(${profileBubleGray})`
        : `url(${profileBubbleDark})`
    };`;
  }
};

// Syled Components
export const StyledGamesHeader = styled.div`
  position: relative;
  height: ${({ account, theme, support, sports }) => {
    if (account) {
      if (
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "qbc"
      ) {
        if (isTablet && orientation()) {
          return `450px`;
        } else {
          return `260px`;
        }
      } else if (
        theme.skin === "pub" ||
        theme.skin === "bnk" ||
        theme.skin === "yet" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak"
      ) {
        if (isTablet && orientation()) {
          return `450px`;
        } else {
          return `340px`;
        }
      } else if (theme.skin === "rac") {
        return ``;
      } else {
        return `450px`;
      }
    } else {
      if (
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak" ||
        theme.skin === "qbc"
      ) {
        if (
          support ||
          theme.skin === "fun" ||
          theme.skin === "hyc" ||
          theme.skin === "yak"
        ) {
          return theme.skin === "nbc" ||
            theme.skin === "yet" ||
            theme.skin === "fun" ||
            (theme.skin === "yak" && support)
            ? `460px`
            : `360px`;
        } else {
          return `260px`;
        }
      } else if (theme.skin === "rac") {
        return sports ? `100px` : ``;
      } else {
        return `300px`;
      }
    }
  }};
  background-color: ${({ theme }) =>
    theme.skin === "abc" ? "var(--bgHederABC)" : "var(--headerbg)"};
  background-image: ${({ theme, account }) => {
    if (theme.skin === "plc") {
      if (theme.current === "light") {
        return `url(${account ? "" : CloudTop}), url(${CloudBottom})`;
      } else {
        return `url(${account ? "" : CloudTopDark}), url(${CloudBottomDark})`;
      }
    } else if (theme.skin === "abc") {
      if (theme.current === "light") {
        return `url(${account ? "" : BanerLightABC})`;
      } else {
        return `url(${account ? "" : BanerDarkABC})`;
      }
    } else if (theme.skin === "nbc") {
      return `url(${account ? "" : BanerNBC})`;
    } else if (theme.skin === "yet") {
      return `url(${account ? "" : BanerYET})`;
    } else if (theme.skin === "fun") {
      return `url(${account ? "" : BanerFUN})`;
    } else if (theme.skin === "cac") {
      return `url(${account ? BanerCAC : BanerCAC})`;
    } else if (theme.skin === "hyc") {
      return `url(${account ? BanerHYC : BanerHYC})`;
    } else if (theme.skin === "yak") {
      return `url(${account ? "" : BanerYAK})`;
    } else if (theme.skin === "qbc") {
      return `url(${account ? "" : BanerQBC})`;
    } else if (theme.skin === "pub") {
      return account
        ? `linear-gradient(to bottom,rgb(0 0 0 / 70%),rgb(0 0 0 / 0%)), url(${BanerPub})`
        : `url(${BanerPub})`;
    }
  }};

  background-position: ${({ account, theme }) => {
    if (account) {
      return "bottom center";
    } else {
      if (theme.skin === "abc" || theme.skin === "nbc") return "25% 85%";
      if (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc")
        return "";
      if (theme.skin === "yet") return "25% 78%";
      if (theme.skin === "yet") return "25% 78%";
      if (theme.skin === "yak") return "top 50px center";
      if (theme.skin === "fun" || theme.skin === "qbc") return "center";
      else return "center 100px, bottom center";
    }
  }};
  background-size: ${({ account, theme, support }) => {
    if (account) {
      if (theme.skin === "pub" || theme.skin === "cac") {
        return `cover`;
      } else if (theme.skin === "hyc") {
        return `100% 100%`;
      } else {
        return "contain";
      }
    } else {
      if (
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun"
      ) {
        if (support) {
          return `cover`;
        } else {
          return `100%`;
        }
      } else if (theme.skin === "pub" || theme.skin === "cac") {
        return isMobile ? `auto` : `100%, contain`;
      } else if (theme.skin === "hyc") {
        return isMobile ? `100% 100%` : `100% 300px`;
      } else if (theme.skin === "yak") {
        return isMobile ? `80%, contain` : `40%, contain`;
      } else if (theme.skin === "qbc") {
        return `cover`;
      } else {
        return `60%, contain`;
      }
    }
  }};
  background-repeat: no-repeat, no-repeat;

  @media screen and (max-width: 767px) {
    height: ${({
      account,
      theme,
      support,
      gameCategoryStyleMobile,
      accountRoute,
      sports,
    }) =>
      account
        ? theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "qbc"
          ? "450px"
          : "570px"
        : theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "qbc"
        ? support
          ? "500px"
          : gameCategoryStyleMobile
          ? theme.skin === "yet" || theme.skin === "fun" || theme.skin === "qbc"
            ? "150px"
            : "90px"
          : isMobileOnly && accountRoute
          ? "120px"
          : theme.skin === "cac" || theme.skin === "hyc"
          ? "120px"
          : "200px"
        : (theme.skin === "pub" || theme.skin === "yak") && isMobileOnly
        ? support
          ? "450px"
          : "190px"
        : theme.skin === "rac" && isMobileOnly
        ? sports
          ? "80px"
          : "0px"
        : "250px"};
    background-position: ${({ account, theme, sportsbook }) =>
      account
        ? "bottom center"
        : theme.skin === "nbc" ||
          theme.skin === "abc" ||
          theme.skin === "yet" ||
          theme.skin === "fun"
        ? "25% 100%"
        : theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          sportsbook
        ? ""
        : "center 100px, bottom center"};
    @media screen and (orientation: landscape) {
      height: ${({
        account,
        theme,
        support,
        gameCategoryStyleMobile,
        accountRoute,
      }) =>
        account
          ? "480px"
          : theme.skin === "abc" ||
            theme.skin === "nbc" ||
            theme.skin === "yet" ||
            theme.skin === "fun"
          ? support
            ? "350px"
            : gameCategoryStyleMobile
            ? "90px"
            : isMobileOnly && accountRoute
            ? "120px"
            : "200px"
          : ""};
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: ${({ account, theme, support, accountRoute }) => {
      if (account) {
        if (
          theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun"
        ) {
          if (!isMobileOnly && !orientation()) {
            return `400px`;
          } else if (isTablet && orientation()) {
            return `400px`;
          } else {
            return `350px`;
          }
        } else {
          return `500px`;
        }
      } else {
        return theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun"
          ? support
            ? `400px`
            : isMobileOnly && accountRoute
            ? "120px"
            : "250px"
          : `250px`;
      }
    }};
    background-position: ${({ account, theme }) =>
      account
        ? "bottom center"
        : theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun"
        ? ""
        : theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
        ? ""
        : "center 100px, bottom center"};
  }
`;

export const DarkLayer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ContentTopWrapper = styled.div`
  a {
    color: ${({ theme }) =>
      theme.skin === "abc"
        ? isMobileOnly
          ? "var(--subTextColorNav)"
          : "#6998E0"
        : theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "yak"
        ? "var(--aboutH2Color)"
        : "var(--acolor)"};
  }
  .btn {
    font-family: ${({ theme }) => theme.skin === "pub" && "Montserrat"};
    font-weight: ${({ theme }) => theme.skin === "pub" && "400"};
    background-color: transparent;
    margin: 0;
    padding: ${({ theme }) => (theme.skin === "rac" ? "" : "0.7rem 0.7rem")};
  }

  ${(props) => {
    if (props.profileMenu) {
      if (isTablet) {
        return `
          width: 630px;
          margin: 0 auto;
          margin-top: ${
            props.theme.skin === "rac"
              ? "-25px"
              : props.theme.skin === "nbc" ||
                props.theme.skin === "yet" ||
                props.theme.skin === "fun"
              ? "0"
              : "40px"
          };
          
          @media screen and (orientation: landscape) {
            @media (min-width: 1025px) and (max-width: 1366px) {
              margin-top: ${
                props.theme.skin === "rac"
                  ? "-40px"
                  : props.theme.skin === "pub" ||
                    props.theme.skin === "plc" ||
                    props.theme.skin === "cac" ||
                    props.theme.skin === "hyc"
                  ? "35px"
                  : "-50px"
              };
            }
            
          }
        `;
      } else {
        // MOBILN VERZIJA MENIJA
        return `
          .active {
            color: ${
              props.theme.skin === "pub" ||
              props.theme.skin === "rac" ||
              props.theme.skin === "cac" ||
              props.theme.skin === "yak" ||
              props.theme.skin === "qbc"
                ? `#fff`
                : props.theme.skin === "nbc"
                ? `#ed338c`
                : props.theme.skin === "yet" || props.theme.skin === "fun"
                ? `#fff`
                : props.theme.skin === "hyc"
                ? `var(--smallHeaderABC)`
                : `#ae0005`
            };
          }
          
          a {
            padding: 0.2rem !important;
            font-weight: 600;
            font-size: ${
              props.theme.skin === "abc"
                ? "0.7rem"
                : props.theme.skin === "rac"
                ? "1rem"
                : "0.6rem"
            };
          }
          /* max-width: ${
            props.theme.skin === "abc" && isMobileOnly && "375px"
          }; */
          /* @media screen and (orientation: landscape) {
              max-width: ${
                props.theme.skin === "abc" && isMobileOnly && "555px"
              };
            } */

          @media screen and (max-width: 1024px) {
            max-width:  ${
              props.theme.skin === "abc" && isMobileOnly ? "100%" : "555px"
            };
            height: 100%;
            margin: 0 auto;
            display: ${props.theme.skin === "abc" ? "flex !important" : ""};
            flex-wrap: ${props.theme.skin === "abc" ? "wrap" : ""};
            justify-content: ${
              props.theme.skin === "abc"
                ? isMobileOnly
                  ? "space-evenly"
                  : "space-between"
                : ""
            };

            @media screen and (orientation: landscape) {
              justify-content: ${props.theme.skin === "abc" ? "center" : ""};
              max-width:  600px;
            }

            a {
              font-size: ${props.theme.skin === "abc" ? "" : "0.5rem"};
              //color: rgba($color: #fff, $alpha: 0.8);
            }
          }
        `;
      }
    } else {
      // DESKTOP VERZIJA MENIJA
      if (props.showProfileMenu) {
        return `
        -webkit-box-shadow: 0px 4px 10px -5px var(--headerbg);
        -moz-box-shadow: 0px 4px 10px -5px var(--headerbg);
        box-shadow: 0px 4px 10px -5px var(--headerbg);
        border-bottom-left-radius: ${
          props.theme.skin === "abc" || props.theme.skin === "yet"
            ? "0.5rem"
            : props.theme.skin === "pub" || props.theme.skin === "rac"
            ? "0.3rem"
            : props.theme.skin === "fun" || props.theme.skin === "cac"
            ? "20px"
            : props.theme.skin === "hyc"
            ? "10px"
            : "15px"
        };
        border-bottom-right-radius: ${
          props.theme.skin === "abc" || props.theme.skin === "yet"
            ? "0.5rem"
            : props.theme.skin === "pub" || props.theme.skin === "rac"
            ? "0.3rem"
            : props.theme.skin === "fun" || props.theme.skin === "cac"
            ? "20px"
            : props.theme.skin === "hyc"
            ? "10px"
            : "15px"
        };
        border: ${
          (props.theme.skin === "cac" || props.theme.skin === "qbc") &&
          "1px solid #424141"
        };
        background-color: #fff;
        width: 200px;
        position: absolute;
        top: 80px;
        text-align: left;
        z-index: 100;
        background-color: ${
          props.theme.skin === "abc" || props.theme.skin === "bnk"
            ? "#206BC8"
            : "var(--profileMenu)"
        };
        padding-bottom: 20px;
  
        a {
          padding: 15px 30px;
          font-size: 13px;
          color: ${
            props.theme.skin === "abc" || props.theme.skin === "bnk"
              ? "#fff"
              : "var(--profileBtn)"
          };
          width: 100%;
          float: left;
          bottom: 0px;
          text-align: left;
          z-index: 100;
          border-radius: 0;
        }
        a:hover {
          color: ${
            props.theme.skin === "abc" || props.theme.skin === "yet"
              ? "#fff"
              : "var(--profileBtnHover) !important"
          };
          background-color: ${
            props.theme.skin === "abc"
              ? "#0554B3"
              : props.theme.skin === "bnk"
              ? "#e1f4f3"
              : props.theme.skin === "yet"
              ? "#07415F"
              : props.theme.skin === "yak"
              ? "#3E076C"
              : ""
          };
          border-radius: 0;
        }
        `;
      } else {
        return `
          display: none;
        `;
      }
    }
  }}
`;

export const ProfileMenuWrapper = styled.div.attrs((props) => ({
  className: props.serchLogo ? "w-100" : "",
}))`
  top: ${({ theme, gameSearch }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun"
      ? "130px"
      : theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
      ? gameSearch
        ? "240px"
        : "160px"
      : "160px"};
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  max-width: 100%;
  z-index: ${({ theme }) =>
    (theme.skin === "pub" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "rac") &&
    theme.skin === "rac"
      ? "4"
      : "3"};
  .list-group {
    padding: 0px;
    box-shadow: ${({ theme }) =>
      theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
        ? "0px 0px 10px -2px var(--profileMenu)"
        : "0px 0px 10px -2px var(--headerbg)"};
    text-align: left;
    border-radius: ${({ theme }) => theme.skin === "pub" && "0.3rem"};
    margin: ${({ theme }) => theme.skin === "pub" && "0 auto"};
    border: ${({ theme }) =>
      (theme.skin === "cac" || theme.skin === "qbc") && "1px solid #424141"};
    margin-top: ${({ theme }) =>
      (theme.skin === "hyc" || theme.skin === "cac") && "10px"};
  }
  .list-group-item {
    color: var(--tabletext);
    border: none;
    -webkit-box-shadow: 0px 0px 2px 0px var(--tabletext);
    -moz-box-shadow: 0px 0px 2px 0px var(--tabletext);
    box-shadow: 0px 0px 2px 0px var(--tabletext);
    span {
      position: absolute;
      top: 5px;
      right: 20px;
      font-size: 12px;
      opacity: 0.6;
    }
  }
  @media screen and (max-width: 767px) {
    top: ${({ theme }) => (theme.skin === "abc" ? "125px" : "160px")};
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    top: ${({ theme }) =>
      (theme.skin === "pub" || theme.skin === "cac") && "150px"};
  }
`;

export const AccountHeader = styled.h1`
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  color: ${({ theme }) =>
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "hyc"
      ? "#fff"
      : "#0554b3"};
  font-size: 1.8rem;
  padding: ${isTablet || isDesktop ? "2rem 0" : "1rem 0 0 0"};
  width: ${isMobile ? "100%" : "1200px"};
  height: ${isTablet && "100px"};
  margin: 0 auto;
  z-index: ${({ theme }) => theme.skin === "rac" && "1"};
  /* background: rgb(244, 244, 244); */
  background: var(--profileHeaderBgABC);

  @media screen and (max-width: 767px) {
    position: relative;
    top: 4rem;
  }
`;

export const AboutHeader = styled.h1`
  text-align: center;
  font-weight: ${({ theme }) => (theme.skin === "fun" ? "400" : "900")};
  font-family: ${({ theme }) => theme.skin === "fun" && "RavagerSansRegular"};
  text-transform: ${({ theme }) =>
    theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak"
      ? "capitalize"
      : "uppercase"};
  font-size: ${({ payments, theme }) =>
    payments
      ? "230px"
      : isTablet
      ? "9vw"
      : theme.skin === "nbc"
      ? "60px"
      : theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak"
      ? "40px"
      : "180px"};
  width: 100vw;
  height: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun"
      ? isMobileOnly
        ? ""
        : "240px"
      : theme.skin === "yak" && isMobile
      ? ""
      : "300px"};
  margin: 0 auto;
  padding-top: ${({ payments, theme }) =>
    payments
      ? "1rem"
      : theme.skin === "nbc" || theme.skin === "yet" || theme.skin === "fun"
      ? "5rem"
      : theme.skin === "yak"
      ? "15rem"
      : "2rem"};
  vertical-align: top;
  text-shadow: var(--accountTextShadow);
  color: ${({ payments, theme }) =>
    payments
      ? "var(--paymentHeaderColor)"
      : theme.skin === "nbc" || theme.skin === "fun"
      ? "#fff"
      : "var(--aboutHeaderColor)"};

  user-select: none;
  overflow: hidden;

  ${(props) => {
    if (props.payments) {
      return `
      mask-image: linear-gradient(0deg, transparent 0px, red 76px);
      -webkit-mask-image: linear-gradient(0deg, transparent 0px, red 76px);
      `;
    }
  }}

  @media (max-width: 320px) {
    padding-top: 9rem;
    font-size: 14vw;
  }

  @media (min-width: 321px) and (max-width: 767px) {
    /* height: 220px; */
    /*     padding-top: 5rem; */
    font-size: ${({ payments, theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "7.5vw"
        : payments
        ? "18vw"
        : "14.5vw"};
    overflow: hidden;
    line-height: normal;
    padding-top: ${({ theme }) => (theme.skin === "yak" ? "10rem" : "6rem")};

    @media screen and (orientation: landscape) {
      padding-top: 2rem;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    /* height: 220px; */
    position: relative;
    font-size: ${isTablet ? "9vw" : "14.5vw"};
    padding-top: ${isTablet ? "8rem" : "7rem"};
    overflow: hidden;
    line-height: 1;
    @media screen and (orientation: landscape) {
      padding-top: ${isTablet && "7rem"};
    }
  }
`;

export const ContentCard = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px;
  width: ${({ theme }) =>
    theme.skin === "abc" || (theme.skin === "rac" && isMobile)
      ? isMobileOnly
        ? "70px"
        : "60px"
      : theme.skin === "hyc"
      ? "70px"
      : theme.skin === "rac" && !isMobile
      ? ""
      : "80px"};
  height: ${({ theme }) =>
    theme.skin === "abc" || (theme.skin === "rac" && isMobile)
      ? isMobileOnly
        ? "70px"
        : "60px"
      : theme.skin === "hyc"
      ? "70px"
      : theme.skin === "rac" && !isMobile
      ? ""
      : "80px"};
  margin: 0 auto;
  margin-bottom: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun") &&
    !isMobileOnly
      ? "10px"
      : ""};
  flex-direction: column;

  /*  margin-bottom: ${({ theme }) => (theme.skin === "abc" ? "10px" : "")}; */
  img {
    margin: auto;
  }
  p {
    margin: auto;
    color: #0554b3;
    font-weight: bold;
    font-size: 2.2rem;
  }
  div {
    color: red;
  }
  ${(props) => {
    if (props.profileMenu) {
      if (props.theme.skin === "plc") {
        if (props.showProfileMenu) {
          return `
          display: flex;
          background-image: ${bubleBackground(props)}
          
          `;
        } else {
          return `
            display: flex;
            background-image: ${bubleBackgroundSecond(props)}
          `;
        }
      } else {
        if (props.showProfileMenu) {
          return `
          display: flex;
          background-color: ${
            props.theme.skin !== "abc" &&
            props.theme.skin !== "plc" &&
            props.theme.skin !== "rac"
              ? `transparent`
              : props.theme.skin === "abc" && isMobileOnly
              ? "var(--inputbgABC)"
              : props.theme.skin === "rac" && isMobileOnly
              ? `var(--racMobileMenuButtonCol)`
              : `#EAEBEF`
          };
          border-radius: 0.5rem;
          
          `;
        } else {
          return `
            display: flex;
            background-color: ${
              props.theme.skin !== "abc" && props.theme.skin !== "plc"
                ? `transparent`
                : `#0554b3`
            };
            border-radius: 0.5rem;


          `;
        }
      }
    } else {
      return `
          display: none;
        `;
    }
  }}
`;

export const StyledInnerDescription = styled.div`
  color: #6998e0;
  font-weight: 600;
  font-size: 0.7rem;
`;

// PROFILE ICONS PLC

export const StyledProfile = styled(Profile)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

export const StyledCashback = styled(Cashback)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

export const StyledBonus = styled(Bonus)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

export const StyledNewPassword = styled(NewPassword)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

export const StyledTransactions = styled(Transactions)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

export const StyledHisory = styled(Hisory)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

export const StyledPromotion = styled(Promotion)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

export const StyledLimits = styled(Limits)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

export const StyledVerification = styled(Verification)`
  fill: var(--acolor);
  width: 20px;
  margin: auto;
`;

// PROFILE ICONS FOR ABC
export const StyledProfileABC = styled(ProfileABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledCashbackABC = styled(CashbackABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledBonusABC = styled(BonusABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledNewPasswordABC = styled(NewPasswordABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledTransactionsABC = styled(TransactionsABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledHisoryABC = styled(HisoryABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledPromotionABC = styled(PromotionABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledLimitsABC = styled(LimitsABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledVerificationABC = styled(VerificationABC)`
  width: 25px;
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

export const StyledTournamentsABC = styled(TournamentsABC)`
  margin: auto;
  .st0 {
    fill: ${({ accountroute, theme }) =>
      accountroute
        ? "#fff"
        : isMobileOnly && theme.current === "dark"
        ? "#fff"
        : "#0554b3"};
  }
`;

//PROFILE ICONS PUB, NBC, YET, FUN, CAC, YAK, QBC

export const StyledProfilePUB = styled(ProfilePUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledCashbackPUB = styled(CashbackPUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;
  stroke: transparent;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledBonusPUB = styled(BonusPUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledNewPasswordPUB = styled(NewPasswordPUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledTransactionsPUB = styled(TransactionsPUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledHistoryPUB = styled(HisoryPUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledPromotionsPUB = styled(PromotionsPUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledLimitsPUB = styled(LimitsPUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledVerificationPUB = styled(VerificationPUB)`
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const StyledTournamentsPUB = styled(TournamentsPUB)`
  margin: auto;
  fill: ${({ theme }) =>
    theme.skin === "fun"
      ? "#85643673"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "transparent"
      : theme.skin === "yak"
      ? "var(--bgColorABC)"
      : "var(--acolor)"};
  width: 71px;
  margin: auto;

  circle {
    opacity: ${({ theme }) => theme.skin === "fun" && "1"};
    fill-opacity: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    stroke: transparent;
  }

  #mask-fill-icon {
    fill: ${({ theme }) =>
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : "#9a875d"};
  }
`;

export const SupportButtonsWrapper = styled.div.attrs({
  className: "d-flex justify-content-around mx-auto my-0",
})`
  padding-top: ${({ theme }) =>
    (theme.skin === "pub" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "qbc") &&
    "10rem !important"};
  a {
    text-decoration: none;
    min-width: 180px;
  }
  width: ${isTablet ? "100%" : "65%"};
  flex-wrap: ${isMobileOnly && "wrap"};
`;

export const SupportDiv = styled.div.attrs({
  className: "text-center ",
})`
  margin: 1rem 0;
  &:first-child {
    margin: 1rem 0 0 0;
  }
  div {
    color: #fff;
    font-weight: 700;
  }

  span {
    @-moz-document url-prefix() {
      margin-top: 0 !important;
    }
    display: inline-flex;
    width: ${isMobileOnly && "180px"};
    justify-content: ${isMobileOnly && "center"};
    transition: all 0.35s ease 0s;
    div {
      text-decoration: none !important;
      font-family: inherit !important;
      color: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "pub" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc"
          ? "#fff"
          : theme.skin === "yet"
          ? "#07415F !important"
          : theme.skin === "yak"
          ? "#7b38ce !important"
          : "#350e46 !important"};
      :hover {
        color: ${({ theme }) =>
          theme.skin === "abc"
            ? "#CF1F2C !important"
            : theme.skin === "pub" ||
              theme.skin === "cac" ||
              theme.skin === "hyc"
            ? "inherit"
            : theme.skin === "yet" ||
              theme.skin === "fun" ||
              theme.skin === "yak"
            ? "#fff !important"
            : "#a18bac !important"};
      }
      &:first-child {
        @-moz-document url-prefix() {
          padding-bottom: 0;
        }
      }
    }
  }
`;

export const SupportLink = styled.a`
  background-color: ${({ theme, phone }) =>
    theme.skin === "abc"
      ? "#0554b3"
      : theme.skin === "pub"
      ? "transparent"
      : theme.skin === "fun"
      ? phone
        ? "var(--racProfileMenuCol)"
        : "#fa2c70"
      : theme.skin === "cac"
      ? phone
        ? "var(--menuhover)"
        : "#fdb448"
      : theme.skin === "hyc"
      ? phone
        ? "#fff"
        : "#f4ef00"
      : theme.skin === "yak"
      ? phone
        ? "var(--racProfileMenuCol)"
        : "#fff"
      : "#fff"};
  border-radius: ${({ theme }) =>
    theme.skin === "fun" || theme.skin === "cac"
      ? "30px"
      : theme.skin === "yak"
      ? "20px"
      : "0.5rem"};
  font-size: 1rem;
  border: ${({ theme }) => theme.skin === "pub" && "2px solid #9A875D"};
  color: ${({ theme, phone }) =>
    theme.skin === "nbc"
      ? "#350e46"
      : theme.skin === "yet"
      ? "#07415F"
      : theme.skin === "fun" && phone
      ? "var(--acolor)"
      : theme.skin === "hyc"
      ? "#000"
      : theme.skin === "yak" && !phone
      ? "#7b38ce"
      : "#fff"};
  font-weight: 700 !important;
  padding: ${({ theme }) =>
    theme.skin === "fun"
      ? "0.8rem 1.5rem"
      : theme.skin === "cac"
      ? "0.6rem 1.5rem"
      : "0.5rem 1rem"};
  position: relative;
  top: 10px;

  &:hover {
    color: ${({ theme, phone }) =>
      theme.skin === "hyc"
        ? "#000 !important"
        : (theme.skin === "pub" ||
            theme.skin === "yet" ||
            theme.skin === "cac") &&
          "#fff !important"};
    border-color: ${({ theme }) => theme.skin === "pub" && "#fff"};
    background-color: ${({ theme, phone }) =>
      theme.skin === "yet"
        ? "#358bb7"
        : theme.skin === "fun"
        ? phone
          ? "#ffdba8"
          : "#ee1c61"
        : theme.skin === "cac"
        ? phone
          ? "#322d2e"
          : "#edab49"
        : theme.skin === "hyc"
        ? phone
          ? ""
          : "#fffb31"
        : ""};
  }

  ${(props) => {
    if (props.phone) {
      return `
      background-color: ${props.theme.skin === "yet" && "#07415F"};
      color: ${props.theme.skin === "yet" && "#fff"};
      &:before {
      font-family: "Material Icons";
      content: ${
        props.theme.skin !== "fun" &&
        props.theme.skin !== "cac" &&
        props.theme.skin !== "hyc" &&
        "'phone '"
      };
      vertical-align: middle;
      font-size: 1rem;
  }
      `;
    } else if (props.email) {
      return `
      &:before {
      font-family: "Material Icons";
      content: ${
        props.theme.skin !== "fun" &&
        props.theme.skin !== "cac" &&
        props.theme.skin !== "hyc" &&
        "'alternate_email '"
      };
      vertical-align: middle;
      font-size: 1rem;
  }
      `;
    } else {
      return `
      &:before {
      font-family: "Material Icons";
      content: ${
        props.theme.skin !== "fun" &&
        props.theme.skin !== "cac" &&
        props.theme.skin !== "hyc" &&
        "'textsms '"
      };
      vertical-align: middle;
      font-size: 1rem;
  }
      `;
    }
  }}
`;
