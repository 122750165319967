import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { isMobile, isMobileOnly } from "react-device-detect";
import Cookies from "js-cookie";
/* import "../../../plc/src/data/scss/components/slider.scss"; */
import Logo from "./Logo";
import {
  DividerPub,
  Promoslider,
  QuickRegisterWrapper,
  StyledImg,
  StyledJoinCasino,
  StyledSlide,
  StyledSlideControllDetails,
  StyledSlideControlls,
  StyledSlider,
  StyledSliderControll,
  WelcomeTextWrapper,
} from "../styledComponents/StyledSlider";
import { StyledLink } from "../styledComponents/CommonStyledComponents";
import React, { useState } from "react";
import MainDescription from "./MainDescription";
/* import QuickGameCategories from "./QuickGameCategories"; */

const Slides = ({
  banners,
  currentLanguage,
  currentTheme,
  player,
  server,
  setIsOpen,
  skin,
  landingImagePath,
  defaultLang,
  defaultCurrency,
}) => {
  const { t, i18n } = useTranslation();
  const { currency, country } = player;
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const baseUrl = `/${
    i18n.language || window.localStorage.i18nextLng || defaultLang
  }`;

  const isSWEIp =
    server.geoIP.isoCode.toLowerCase() === "se" || country === "se"
      ? true
      : false;
  //const isUKIp = server && server.geoIP.isoCode.toLowerCase() === "gb" ? true : false;
  const geoIpFormated = server.geoIP.isoCode.toLowerCase();

  let playerCurrency = "eur";

  if (player) {
    playerCurrency = currency;
  } else if (
    skin !== "yet" &&
    skin !== "fun" &&
    skin !== "cac" &&
    skin !== "hyc" &&
    skin !== "yak" &&
    skin !== "rac"
  ) {
    switch (server && geoIpFormated) {
      case "gb":
        playerCurrency = "gbp";
        break;
      case "se":
        playerCurrency = "sek";
        break;
      case "fi":
        playerCurrency = "eur";
        break;
      default:
        playerCurrency = defaultCurrency.toLowerCase();
        break;
    }
  } else {
    playerCurrency = defaultCurrency.toLowerCase();
  }

  //const isUK = player.country === "gb" ? true : false;
  // let playerCurrency =
  //currency !== undefined ? currency : isUKIp ? "gbp" : "eur";

  //playerCurrency = isUKIp && "gbp";
  let bannerType =
    skin !== "abc" && skin !== "plc" && skin !== "rac"
      ? "homepage_out"
      : "home_out_new";
  //console.log(playerCurrency);

  if (player) {
    bannerType =
      skin !== "abc" && skin !== "plc" && skin !== "rac"
        ? "homepage_in"
        : "home_in_new";
    if (isMobile) {
      bannerType =
        skin !== "abc" && skin !== "plc" && skin !== "rac"
          ? "homepage_in_mob"
          : "home_in_mob_new";
    }
  } else {
    bannerType =
      skin !== "abc" && skin !== "plc" && skin !== "rac"
        ? "homepage_out"
        : "home_out_new";
    if (isMobile) {
      bannerType =
        skin !== "abc" && skin !== "plc" && skin !== "rac"
          ? "homepage_out_mob"
          : "home_out_mob_new";
    }
  }

  if (Cookies.get("landr")) {
    let banner = JSON.parse(Cookies.get("landr"));
    let bannerLang = banner.page_language === "en" ? "" : banner.page_language;
    if (banner.type === "redirect" && banner.is_enabled) {
      //let image = banner.image;
      //console.log(banner);
      if (isMobile) {
        banner["image"] = banner.mobile_image;
      }
      banner["sslLink"] =
        server.cdn.replace("https://", "") +
        landingImagePath +
        bannerLang +
        "/";
      banner["url"] = "/" + banner.link;
      banners = [banner];
    }
  } else {
    // console.log(banners)
    let allBanners = banners;

    banners = banners.filter(
      (banner) =>
        banner.currency === playerCurrency && banner.section === bannerType
    );

    //for logged in players, if there is no banner for currency, see if there is banner for default currency
    if (player && banners.length < 1) {
      banners = allBanners.filter(
        (banner) =>
          banner.currency === defaultCurrency && banner.section === bannerType
      );
    }
  }

  const changeBannerThema = (banner) => {
    return currentTheme === "dark"
      ? banner.replace("light.", "dark.")
      : banner.replace("dark.", "light.");
  };

  let slider;
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    fade: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleClickTextWrapper = (banner) => {
    if (
      skin === "abc" ||
      skin === "nbc" ||
      skin === "yet" ||
      skin === "fun" ||
      skin === "cac" ||
      skin === "hyc" ||
      skin === "yak"
    ) {
      if (player) {
        document.getElementById("scroll-to-category-onlogin").scrollIntoView();
      } else {
        history.push(baseUrl + banner.url);
      }
    }
  };

  const handleQuickRegister = () => {
    if (email.length > 0 && name.length > 0 && lastName.length > 0) {
      // Store the registration data in localStorage
      localStorage.setItem(
        "registrationData",
        JSON.stringify({
          email: email,
          firstName: name,
          lastName: lastName,
        })
      );

      // Navigate to the registration page
      history.push(baseUrl + "/register").then(() => {
        window.location.reload();
      });
    }
  };

  const displayDescription = (banner) => {
    let bannerObject;
    if (banner.description && banner.description.length) {
      bannerObject = JSON.parse(banner.description);
    } else {
      bannerObject = false;
    }

    return (
      banner.description &&
      bannerObject &&
      (bannerObject[geoIpFormated] || bannerObject["all"]) && (
        <StyledSlideControlls>
          <WelcomeTextWrapper onClick={() => handleClickTextWrapper(banner)}>
            <StyledSlideControllDetails
              dangerouslySetInnerHTML={{
                __html: bannerObject[geoIpFormated]
                  ? bannerObject[geoIpFormated]
                  : bannerObject["all"],
              }}
            ></StyledSlideControllDetails>
            {(skin === "yak" && isSWEIp) || skin === "qbc" ? null : (
              <StyledLink
                id={skin === "plc" ? "scroll-to-category" : ""}
                btn="true"
                slidcontroll="true"
                to={"/" + currentLanguage + banner.url}
              >
                {player ? t("label_play") : t("label_sign_up_now")}
              </StyledLink>
            )}
            {skin === "qbc" && (
              <>
                {!player && (
                  <QuickRegisterWrapper
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleQuickRegister();
                    }}
                  >
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("label_email_address")}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    />
                    <input
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t("label_name")}
                    />
                    <input
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder={t("label_last_name")}
                    />
                    <StyledLink
                      id={skin === "plc" ? "scroll-to-category" : ""}
                      btn="true"
                      as="button"
                      slidcontroll="true"
                      /* onClick={() => handleQuickRegister()} */
                      to={"/" + currentLanguage + banner.url}
                    >
                      {t("label_sign_up_now", "Register")}
                    </StyledLink>
                  </QuickRegisterWrapper>
                )}
                <MainDescription skin={skin} qbcIndex={true} />
              </>
            )}
          </WelcomeTextWrapper>
        </StyledSlideControlls>
      )
    );
  };

  return (
    <Promoslider>
      {/* {skin === "abc" && isMobileOnly && (
        <QuickGameCategories currentLanguage={currentLanguage} />
      )} */}
      <StyledSlider {...settings} ref={(c) => (slider = c)}>
        {banners.map((banner, index) => (
          <StyledSlide key={index}>
            <StyledLink
              to={"/" + currentLanguage + banner.url}
              unclicable={skin === "pub" || skin === "plc"}
            >
              <picture>
                {/*  {banner.image.match(/\.(jpg|png)/g) ? ( */}
                {skin === "plc" && (
                  <Logo
                    isSWEIp={false}
                    setOpen={setIsOpen}
                    classOnScroll={true}
                    defaultLang={defaultLang}
                  />
                )}
                <StyledImg
                  as="source"
                  fetchpriority="high"
                  rel="preload"
                  media="(min-width: 800px)"
                  srcSet={`https://${
                    banner.sslLink + changeBannerThema(banner.image)
                  } 1600w, https://${
                    banner.sslLink + changeBannerThema(banner.image)
                  } 2000w`}
                  type="image/webp"
                />
                <StyledImg
                  rel="preload"
                  fetchpriority="high"
                  alt=""
                  srcSet={`https://${
                    banner.sslLink + changeBannerThema(banner.image)
                  } 1600w, https://${
                    banner.sslLink + changeBannerThema(banner.image)
                  } 2000w`}
                />
              </picture>
            </StyledLink>
            {skin === "nbc" && (
              <StyledSlideControlls>
                <WelcomeTextWrapper
                  onClick={() => handleClickTextWrapper(banner)}
                >
                  <StyledJoinCasino
                    dangerouslySetInnerHTML={{
                      __html: t("label_join_casino", "Join all british casino"),
                    }}
                  ></StyledJoinCasino>
                  <StyledSlideControllDetails
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "label_details",
                        "Get 100% up to £100 +, <br> Always 10% cashback! </br>"
                      ),
                    }}
                  ></StyledSlideControllDetails>
                  <StyledLink
                    btn="true"
                    spancol={skin === "nbc" && player && "d-none"}
                    slidcontroll="true"
                    to={"/" + currentLanguage + banner.url}
                  >
                    {player
                      ? t("label_play")
                      : t("label_sing_up_now", "Register")}
                  </StyledLink>
                </WelcomeTextWrapper>
              </StyledSlideControlls>
            )}
            {(skin === "plc" || skin === "pub") && (
              <StyledSlideControlls>
                <WelcomeTextWrapper
                  onClick={() => handleClickTextWrapper(banner)}
                >
                  {skin === "plc" && (
                    <StyledSlideControllDetails
                      dangerouslySetInnerHTML={{
                        __html: t("label_details"),
                      }}
                    ></StyledSlideControllDetails>
                  )}
                  <StyledLink
                    id={skin === "plc" ? "scroll-to-category" : ""}
                    btn="true"
                    slidcontroll="true"
                    to={"/" + currentLanguage + banner.url}
                  >
                    {player
                      ? t("label_play")
                      : t("label_sing_up_now", "Register")}
                  </StyledLink>
                  {skin === "pub" && <DividerPub />}
                </WelcomeTextWrapper>
              </StyledSlideControlls>
            )}
            {(skin === "yet" ||
              skin === "fun" ||
              skin === "cac" ||
              skin === "hyc" ||
              skin === "yak" ||
              skin === "abc" ||
              skin === "qbc") &&
              banner.description.length > 1 &&
              displayDescription(banner)}
          </StyledSlide>
        ))}
      </StyledSlider>
      {banners.length > 1 && skin === "rac" && (
        <StyledSliderControll>
          <div>
            <button onClick={() => slider.slickPrev()} className="prev">
              <h6> {t("label_prev")} </h6>
            </button>
            <button onClick={() => slider.slickNext()} className="next">
              <h6> {t("label_next")} </h6>
            </button>
          </div>
        </StyledSliderControll>
      )}
    </Promoslider>
  );
};

export default withRouter(Slides);
